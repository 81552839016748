import "../css/style.scss";
import "../css/register/register.scss";
import React, { useState,useEffect } from "react";
import {decode as base64_decode, encode as base64_encode} from "base-64";
import axios from "axios";
import Full from './FullLoad';
import CekEmail from './CekEmail';
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;
const eye1 = <FontAwesomeIcon icon={faEyeSlash} />;



function Register() {
  const [username, setUsername] =  useState("");
  const [email, setEmail] =  useState("");
  const [password, setPass] =  useState("");
  const [password1, setPass1] =  useState("");
  const [nohp, setNohp] =  useState("");
  const [isLoading, setLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [errors, setError] = useState(false);
  const [loader, showLoader, hideLoader] = Full();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isCPasswordDirty, setIsCPasswordDirty] = useState(false);
  const history = useHistory();
  const encoded = base64_encode(email);
  
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const togglePasswordVisiblity1 = () => {
    setPasswordShown1(passwordShown1 ? false : true);
  };


  const onSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    if(email.length==0||password.length==0||nohp.length==0||username.length==0){
      setError(true)
  }
  const formData = new FormData();
  formData.append("name", username);
  formData.append("email", email);
  formData.append("password", password);
  formData.append("confirm_password", password1);
  formData.append("phone_number", nohp);
  
    showLoader();
    await axios
      .post("https://hris.anyargroup.co.id/api/register", formData)
      .then((response) => {
        history.push(`/verifikasi/${encoded}`);
        setLoading(false);
        hideLoader();
      })
      .catch((error) => {
        hideLoader();
        setLoading(false);

        if (error.response.data.code === "reg1") {
          Swal.fire({
            title: "Number and email already available!!",
            icon: "warning",
            showConfirmButton: false,
          });
        } else if (error.response.data.code === "reg2") {
          Swal.fire({
            title: "Email already available!!",
            icon: "warning",
            showConfirmButton: false,
          });
        } else if (error.response.data.code === "reg3") {
          Swal.fire({
            title: "Number already available!!",
            icon: "warning",
            showConfirmButton: false,
          });
        } else if (error.response.data.code === "reg4") {
          const errorMessages = Object.values(error.response.data.errors).flat().join("\n");
        
          Swal.fire({
            title: "Validasi Gagal!",
            text: errorMessages,
            icon: "warning",
            showConfirmButton: true,
          });
        } else if (error.response.data.code === "reg5") {
          Swal.fire({
            title: "Gagal!",
            icon: "warning",
            showConfirmButton: false,
          });
        }
      });
  };
 
  const handleCPassword = (e) => {
      setPass1(e.target.value);
      setIsCPasswordDirty(true);
  }

  useEffect(() => {
      if (isCPasswordDirty) {
          if (password === password1) {
              setShowErrorMessage(false);
          } else {
              setShowErrorMessage(true)
          }
      }
  }, [password1])


  return (
    <>
    {/* <CekEmail sendemail={sendemail}/> */}
    <div className="register">
      <div className="container register-label">
        <div className="label">
          <h4 className="register-title">
            <span className="register-garis">Register User</span>
          </h4>
          <label className="register-title-content-1">
            Mohon mengisi bagian yang ditandai (*) dengan lengkap
          </label>
          <br></br>
          <label className="register-title-content-2">
            Please input your information for required field (*)
          </label>
          <div className="register-garis-1"></div>
        </div>
   
        <div className="content">
          <div className="row form-row">
            <form onSubmit={onSubmit}>
              <div className="col-12 form-col">
                <h5 class="card-title">Personal Data</h5>
                <div class="card">
                  <div class="card-body">
                    <div class="mb-2 row">
                      <label className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label">
                        Username<span className="wajib">*</span>
                      </label>
                      <div className="col-xl-10 col-lg-9 col-md-8 col-sm-7 col-xs-10 col-form-input">
                        <input  type="text" 
                                name="name"
                                placeholder="Input Username"
                                className="form-control form-control-sm input"
                                value={username}
                                onChange={(e) => {
                                  const re = /^[a-z\A-Z\0-9\@\.\ \b]+$/;
                                  if (e.target.value === "" || re.test(e.target.value)) {
                                  setUsername(e.target.value);}}}/>
                            <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                              {errors&&username.length<=0?<label>Username can't be Empty</label>:""}
                            </div>
                      </div>
                    </div>
                    
                    <div class="mb-2 row row-input">
                      <label className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label">
                        Email<span className="wajib">*</span>
                      </label>
                      <div className="col-xl-10 col-lg-9 col-md-8 col-sm-7 col-xs-10 col-form-input">
                        <input  type="email"
                                name="email"
                                value={email}
                                className="form-control form-control-sm input"
                                placeholder="Input Email"
                                onChange={(e) => {
                                  const re = /^[a-z\0-9\@\.\b]+$/;
                                  if (e.target.value === "" || re.test(e.target.value)) {
                                  setEmail(e.target.value);}}}/>
                            <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                              {errors&&email.length<=0?<label>Email can't be Empty</label>:""}
                            </div>
                      </div>
                    </div>

                    <div class="mb-2 row row-password">
                      <label className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label">
                        Password<span className="wajib">*</span>
                      </label>
                      <div className="col-xl-10 col-lg-9 col-md-8 col-sm-7 col-xs-10 col-form-input">
                        <div className="input-group grup-password">
                          <input  onChange={e=>setPass(e.target.value)}
                                  type={passwordShown ? "text" : "password"}
                                  name="password"
                                  id="password"
                                  placeholder="Input Password"
                                  className="form-control form-control-sm input"
                                  aria-describedby="inputGroupPrepend">
                          </input>
                            {!passwordShown && (<i className="eye1" onClick={togglePasswordVisiblity}>{eye1}</i>)}{" "}
                            {passwordShown && (<i className="eye" onClick={togglePasswordVisiblity}>{eye}</i> )}{" "}
                        </div>
                          <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                              {errors&&password.length<=0?<label>Password can't be Empty</label>:""}
                          </div>
                      </div>
                    </div>

                    <div class="mb-2 row row-password">
                      <label className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label">
                        Confirm Password<span className="wajib">*</span>
                      </label>
                      <div className="col-xl-10 col-lg-9 col-md-8 col-sm-7 col-xs-10 col-form-input">
                        <div className="input-group grup-password">
                          <input  type={passwordShown1 ? "text" : "password"}
                                  name="confirm_password"
                                  id="confirm_password"
                                  placeholder="Input Password Confirm"
                                  className="form-control form-control-sm input"
                                  onChange={handleCPassword}>  
                          </input>
                              {!passwordShown1 && (<i className="eye" onClick={togglePasswordVisiblity1}>{eye1}</i>)}{" "}
                              {passwordShown1 && (<i className="eye" onClick={togglePasswordVisiblity1}>{eye}</i>)}{" "}
                        </div>
                        <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                            {errors&&password1.length<=0?<label>Password can't be Empty</label>:""}
                        </div>
                            {showErrorMessage && isCPasswordDirty ? <div style={{color:"red", fontSize:"14px", marginTop:"5px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}> Passwords did not match </div> : ''}
                      </div>
                    </div>

                    <div class="mb-2 row">
                      <label className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label">
                        No Handphone<span className="wajib">*</span>
                      </label>
                      <div className="col-xl-10 col-lg-9 col-md-8 col-sm-7 col-xs-10 col-form-input">
                        <input  type="text"
                                name="phone_number"
                                placeholder="Input Number Phone 'example : 0851xxxxx'"
                                maxLength={13}
                                value={nohp}
                                className="form-control form-control-sm input"
                                onChange={(e) => {
                                const re = /^[0-9\b]+$/;
                                  if (e.target.value === "" || re.test(e.target.value)) {
                                  setNohp(e.target.value); }}}>
                        </input>
                            <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                {errors&&nohp.length<=0?<label>No Handphone can't be Empty</label>:""}
                            </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="col-12 form-noted">
                <div className="card">
                  <div className="card-body">
                    <span className="span-1">
                      Pastikan seluruh data registrasi yang di isi sudah benar.{" "}
                      <span className="span-bold">
                        Data yang sudah diinput tidak dapat diubah
                      </span>
                    </span>
                    <br></br>
                    <span className="span-2">
                      <i>
                        Make sure all registration data that is filled in is
                        correct.{" "}
                        <span className="span-bold">
                          The data that has been input cannot be changed
                        </span>
                      </i>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-12 form-button">
                {!isLoading && (
                  <center>
                    <button type="submit" className="button-register">
                      <a className="regis"> Register</a>
                    </button>
                  </center>
                )}
                {isLoading && (
                  <center>
                    <button type="submit" className="button-register" disabled>
                      <a className="regis">
                        <i className="fas fa-spinner fa-spin"></i> Register
                      </a>
                    </button>
                  </center>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
{loader}
    </>
  );
}

export default Register;

